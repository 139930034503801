<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Rejestr zgłoszeń</h1>

            <ErrorSubscription :isCustomerActive="isCustomerActive()" />

            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-btn text @click="getAllReports()" :disabled="newSignals.selected == undefined || newSignals.selected.length == 0" v-if="adminCanReports">
                                <v-icon color="red">mdi-file-multiple</v-icon><div>Pobierz rejestr (.CSV)</div>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="newSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="newSignals.selected"
                                      :headers="newSignals.headers"
                                      :items="newSignals.signals"
                                      :search="newSignals.search"
                                      :show-select="adminCanReports"
                                      item-key="id"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :expanded.sync="expanded"
                                      show-expand
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>ZGŁOSZENIE</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <p>Informacje dodatkowe:</p>
                                    <p style="margin-bottom: 0px;">Etykieta zgłoszenia: {{ item.title }}</p>
                                    <p style="margin-bottom: 0px;">Dane sygnalisty: {{ item.sygnalistName != null && item.sygnalistName.length > 0 ? item.sygnalistName : 'Brak danych personalnych' }} {{ item.sygnalistEmail != null && item.sygnalistEmail.length > 0 ? '(e-mail: '+item.sygnalistEmail+')' : '(brak wskazanego adresu email)' }} {{item.sygnalistPhone != null && item.sygnalistPhone.length > 0 ? '(telefon: '+item.sygnalistPhone+')' : '(brak wskazanego numeru telefonu)'}}</p>
                                    <p style="margin-bottom: 0px;" v-if="item.msgDecode != null && item.msgDecode.length > 0 && item.msgDecode != 'Brak danych'">Przedmiot naruszenia prawa: {{ item.msgDecode }}</p>
                                    <p v-if="item.msgPersonDecode != null && item.msgPersonDecode.length > 0 && item.msgPersonDecode != 'Brak danych'">Dane osoby, której dotyczy zgłoszenie: {{ item.msgPersonDecode }}</p>
                                    <p style="margin-bottom: 0px;" v-if="item.notes !== null && item.notes.length > 0">Działania następcze:</p>
                                    <div v-if="item.notes !== null && item.notes.length > 0">
                                        <p v-for="i in item.notes" :key="i.id" style="margin-bottom: 5px;">
                                            Data: <b>{{formatDateToDisplay(i.date)}}</b> - Administrator: <b>{{i.adminName}}</b> - Treść: <b>{{i.text}}</b>
                                        </p>
                                    </div>
                                </td>
                            </template>
                        </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

      </div>
  </v-container>
</template>

<script>
    import CryptoJS from 'crypto-js'
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
    export default {
        name: 'Reports',
        components: {
            ErrorSubscription
        },
        data() {
            return {
                newSignals: [],
                signals: [],
                adminCanReports: false,
                itemsPerPage: 10,
                loading: true,
                expanded: [],
                singleExpand: false,
                messages: [],
            }
        },
        async mounted() {
            try {

                if (this.isCustomerActive()) {
                    this.main = (await Service.getAllSignals(20)).data,
                        this.signalsDb = this.main.signals,
                        this.adminCanReports = this.main.login.canReports,
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: true,
                                    value: 'identifier',
                                },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Data zakończenia', value: 'archiveTime' },
                                { text: 'Status', value: 'statusForReport' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'actions' },
                            ],
                            signals: this.signalsDb,

                        };

                        for (var i = 0; i < this.newSignals.signals.length; i++) {
                            var temp = this.odszyfruj(this.newSignals.signals[i]);
                            this.newSignals.signals[i].msgDecode = temp[0];
                            this.newSignals.signals[i].msgPersonDecode = temp[1];
                        }

                        this.loading = false
                }

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords();
                    }
                },
                deep: true,
            },
        },
        methods: {
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                });

                return d;
            },
            odszyfruj(result) {
                try {
                    var k = result.pkid;
                    var s = result.msg;

                    if (k != "0") {
                        var x = this.decrypt(k, s);
                        const arr = JSON.parse(x);

                        var tempArr = arr.find(x => x.name.includes("Przedmiot naruszenia prawa")).value;
                        var tempPerson = arr.find(x => x.name.includes("Dane osoby, której dotyczy zgłoszenie")).value;

                        return [tempArr, tempPerson];

                    } else {
                        var text = '';
                        if (result.type == 2)
                            text = 'Zgłoszenie telefoniczne';
                        else if (result.type == 3)
                            text = 'Zgłoszenie email';
                        else
                            text = 'Zgłoszenie';

                        return ["Brak danych, inny typ zgłoszenia (" + text + ")", "Brak danych, inny typ zgłoszenia (" + text + ")"];
                    }
                } catch {
                    return ["Brak danych", "Brak danych"];
                }
            },
            decrypt(key, message) {
                var m = this.b64decode(message);
                const arr = JSON.parse(m);
                for (var item of arr) {
                    var n = item.name;
                    var v = item.value;
                    if (this.right(n, 10) == '_encrypted' && v != '') {
                        var bytes = CryptoJS.AES.decrypt(v, key);
                        var result = bytes.toString(CryptoJS.enc.Utf8);
                        item.value = result;
                        item.name = item.name.replace("_encrypted", "_decrypted")
                    }
                }
                return JSON.stringify(arr)
            },
            right(str, chr) {
                return str.slice(str.length - chr, str.length);
            },
            b64decode(x) {
                var result = decodeURIComponent(escape(window.atob(x)));
                return result;
            },
            isCustomerActive() {
                return this.$store.state.admin.customerIsActive;
            },
            showSignal(item) {
                this.$router.push({ name: 'Signal', params: { identifier: item.id } })
            },
            async getAllReports() {
                try {
                    var result = (await Service.downloadReportToFile(this.newSignals.selected));

                    var date = new Date();
                    var fileName = "rejestr_zgloszen_" + date.getFullYear() + "_" + date.getMonth() + "_" + date.getDate() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds();

                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = fileName;
                        a.click();
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }

                //localStorage["reportsToPrintForAdmin"] = JSON.stringify(this.newSignals.selected);
                //let routeData = this.$router.resolve({ name: 'reportPrint' });
                //window.open(routeData.href, '_blank');
            },
            async loadRecords() {
                try {
                    this.loading = true;
                    this.main = (await Service.getAllSignals(this.itemsPerPage)).data;
                    this.signalsDb = this.main.signals,
                        this.adminCanReports = this.main.login.canReports,
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: true,
                                    value: 'identifier',
                                },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Data zakończenia', value: 'archiveTime' },
                                { text: 'Status', value: 'statusForReport' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'actions' },
                            ],
                            signals: this.signalsDb,

                        };
                    this.loading = false;
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="./Basic.scss" lang="scss">
</style>
