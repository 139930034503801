<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Subskrypcja</h1>

            <ErrorMainAdmin :isMainAdmin="isMainAdmin()" />

            <div v-if="isMainAdmin()">
                <v-row v-if="showGlobalSettingsModals && user.type == 1">
                    <v-col cols="6">
                        <v-card>
                            <v-card-title class="pa-6 pb-3">
                                <p>Dane do faktury</p>
                                <v-spacer></v-spacer>
                                <v-btn v-if="user.type == 1" @click="changeCustomerData()" style="margin-left:50px;" color="blue">Uzupełnij dane</v-btn>
                            </v-card-title>
                            <v-card-text class="pa-3 pb-0" center>Nazwa: <span :class="customer.companyName != null && customer.companyName.length > 0 ? 'active' : 'inactive'">{{customer.companyName != null && customer.companyName.length > 0 ? customer.companyName : 'Brak'}}</span> </v-card-text>
                            <v-card-text class="pa-3 pb-0">Adres: <span :class="customer.companyAddress != null && customer.companyAddress.length >  0 ? 'active' : 'inactive'">{{customer.companyAddress != null && customer.companyAddress.length >  0 ? customer.companyAddress : 'Brak'}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-0">Kod pocztowy: <span :class="customer.companyZipcode != null && customer.companyZipcode.length > 0 ? 'active' : 'inactive'">{{customer.companyZipcode != null && customer.companyZipcode.length > 0 ? customer.companyZipcode : 'Brak'}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-0">Miasto: <span :class="customer.companyCity != null && customer.companyCity.length > 0 ? 'active' : 'inactive'">{{customer.companyCity != null && customer.companyCity.length > 0 ? customer.companyCity : 'Brak'}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-3">Nip: <span :class="customer.nip != null && customer.nip.length > 0 ? 'active' : 'inactive'">{{customer.nip != null && customer.nip.length > 0 ? customer.nip : 'Brak'}}</span></v-card-text>
                            
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card>
                            <v-card-title class="pa-6 pb-3">
                                <p>Dane konta</p>
                            </v-card-title>
                            <v-card-text class="pa-3 pb-0" center>Imię i nazwisko administratora: <span style="font-weight: bold;">{{user.name}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-0">email: <span style="font-weight: bold;">{{user.email}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-0">Ważność konta firmy/instytucji do: <span :class="customer.status == 1 ? 'active' : 'inactive'">{{formatDateToDisplay(customer.validTo)}}</span>
                            </v-card-text><v-card-text class="pa-3 pb-0">Status firmy/instytucji: <span :class="customer.status == 1 ? 'active' : 'inactive'">{{customer.status == 1 ? 'Aktywny' : 'Nieaktywny'}}</span></v-card-text>
                            <v-card-text class="pa-3 pb-3">{{customer.status != 1 ? 'Poprzednio' : 'Aktualnie'}} wykupiony pakiet: <span :class="customer.version == null || customer.status != 1 ? 'inactive' : 'active'">{{customer.version == null ? 'Brak pakietu' : customer.version + ' na okres: ' + customer.versionTime}}</span></v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="showCustomerDataModal && user.type == 1">
                    <v-col cols="12">
                        <v-card class="pa-3 pb-3">
                            <v-card-title class="pa-6 pb-1">
                                <p>Zmiana danych korespondencyjnych</p>
                                <v-spacer></v-spacer>
                                <v-btn @click="closeModalCustomerData()">Zamknij</v-btn>
                            </v-card-title>
                            <form class="pa-10 pb-10">
                                <v-text-field v-model="customerName"
                                              :error-messages="nameErrors"
                                              label="Nazwa firmy"
                                              required
                                              @input="$v.customerName.$touch()"
                                              @blur="$v.customerName.$touch()"></v-text-field>
                                <v-text-field v-model="adress"
                                              :error-messages="adressErrors"
                                              label="Adres"
                                              required
                                              @input="$v.adress.$touch()"
                                              @blur="$v.adress.$touch()"></v-text-field>
                                <v-text-field v-model="zipcode"
                                              :error-messages="zipcodeErrors"
                                              label="Kod pocztowy format(XX-XXX)"
                                              required
                                              @input="$v.zipcode.$touch()"
                                              @blur="$v.zipcode.$touch()"></v-text-field>
                                <v-text-field v-model="city"
                                              :error-messages="cityErrors"
                                              label="Miasto"
                                              required
                                              @input="$v.city.$touch()"
                                              @blur="$v.city.$touch()"></v-text-field>
                                <v-text-field v-model="nip"
                                              :error-messages="nipErrors"
                                              label="NIP"
                                              @keypress="onlyNumber"
                                              @input="$v.nip.$touch()"
                                              @blur="$v.nip.$touch()"></v-text-field>
                                <br /><br />
                                <v-btn class="mr-4"
                                       @click="submitCustomerData" color="green" :disabled="customerName.length < 3 || adress.length < 3 || zipcode.length < 5 || city.length < 3">
                                    Zatwierdź
                                </v-btn>
                            </form>
                        </v-card>

                    </v-col>
                </v-row>

                <v-row v-if="showOrderSubscryptionModal && user.type == 1">
                    <v-col cols="12">
                        <v-card class="pa-3 pb-3">
                            <v-card-title class="pa-6 pb-1">
                                <p>Składanie zamówienia</p>
                                <v-spacer></v-spacer>
                                <v-btn @click="closeModalOrderSubscryption()">Zamknij</v-btn>
                            </v-card-title>
                            <form>
                                <v-row v-if="haveDiscount == false">
                                    <v-col cols="8">
                                        <v-text-field v-model="discountCode" label="Kod rabatowy"></v-text-field>
                                        <v-card-text class="small-padd" style="font-size: 10px;"> *jeżeli posiadasz kod rabatowy wypełnij pole i zatwierdź - zostaną naliczone zniżki do zamówienia</v-card-text>
                                        <v-btn @click="checkDiscountCode()" color="green" :disabled="discountCode.length == 0"> Nalicz rabat </v-btn>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col cols="8">
                                        <v-select v-model="selectPackage"
                                                  :items="packagesToSelect"
                                                  :error-messages="selectErrors3"
                                                  item-text="name"
                                                  label="Wybrany pakiet"
                                                  required
                                                  @change="changeSelectedPackage()"></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8">
                                        <v-select v-model="selectModel"
                                                  :items="paymentModels"
                                                  item-text="name"
                                                  :error-messages="selectErrors"
                                                  label="Okres"
                                                  required
                                                  @change="changeSelectedTime()"></v-select>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col cols="8">
                                        <v-select v-model="selectPaymentMethod"
                                                  :items="paymentMethods"
                                                  item-text="name"
                                                  :error-messages="selectErrors2"
                                                  label="Metoda płatności"
                                                  required></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8">
                                        <v-card-text style="font-size: 20px;"> <span>Całkowita kwota netto: <b>{{priceToPay}} zł</b></span> <span style="margin-left: 50px;"> Cena brutto do zapłaty: <b>{{priceToPayBrutto}} zł</b></span></v-card-text>
                                        <v-card-text v-if="customer.versionId != null && customer.versionId < checkPackage()" style="font-size: 14px; color: red;">Uwaga! Zamawiając wyższy pakiet niż obecnie posiadany, dotychczasowa subskrypcja zostanie skrócona do dnia, w którym zostanie zamówiony wyższy pakiet.</v-card-text>
                                    </v-col>
                                </v-row>
                                <br />
                                <v-card-text class="small-padd">Dane do faktury:</v-card-text>
                                <v-card>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-card-text class="small-padd"> <b>Sprzedawca</b></v-card-text>
                                            <v-card-text class="small-padd"> Nazwa: {{mwcCompanyData.companyName}}</v-card-text>
                                            <v-card-text class="small-padd"> Adres: {{mwcCompanyData.companyZipcode}} {{mwcCompanyData.companyCity}}, {{mwcCompanyData.companyAddress}}</v-card-text>
                                            <v-card-text class="small-padd"> NIP: {{mwcCompanyData.nip}}</v-card-text>
                                            <v-card-text class="small-padd"> Email: {{mwcCompanyData.email}}</v-card-text>
                                            <v-card-text class="small-padd"> Telefon: {{mwcCompanyData.phone}}</v-card-text>
                                        </v-col>
                                        <v-col>
                                            <v-card-text class="small-padd"> <b>Nabywca</b></v-card-text>
                                            <v-card-text class="small-padd"> Nazwa: {{customer.companyName}}</v-card-text>
                                            <v-card-text class="small-padd"> Adres: {{customer.companyZipcode}} {{customer.companyCity}}, {{customer.companyAddress}}</v-card-text>
                                            <v-card-text class="small-padd"> NIP: {{customer.nip}}</v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-row>
                                    <v-col cols="12">
                                        <v-card-text>
                                            Klikając ZAMAWIAM I PŁACĘ zgadzasz się na zakup wybranego pakietu za pośrednictwem Przelewy24 i potwierdzasz poprawność wprowadzonych danych. Potwierdzasz też, że jest Ci znana nasza polityka prywatności oraz regulamin.
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                                <v-btn class="mr-4"
                                       @click="submitSubscriptionOrder" color="green">
                                    ZAMAWIAM i PŁACĘ
                                </v-btn>
                            </form>
                        </v-card>

                    </v-col>
                </v-row>

                <v-row v-if="showGlobalSettingsModals && showOrderSubscryptionModal == false && user.type == 1">
                    <v-col cols="12">
                        <v-card>
                            <div id="pricing" class="pricing-area default-padding bottom-less">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-8 offset-lg-2">
                                            <div class="site-heading text-center">
                                                <h3>Cennik systemu</h3>
                                                <v-card-text class="small-padd" v-if="haveDiscount == true">Do poniższych cen zostały naliczone rabaty uzyskane dzięki programowi partnerskiemu. Rabaty uzyskano od partnera: {{partnerName}} w wysokości <b>{{discountPercent}}%</b></v-card-text>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pricing pricing-simple text-center">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6" v-for="item in packages" :key="item.id">
                                                <div class="pricing-item">
                                                    <div>
                                                        <v-card-text class="pricing-header">
                                                            <h4>Wersja {{item.name}}</h4>
                                                            <div v-for="prices in item.prices[0].priceCombination" :key="prices.packageId">
                                                                <h2> {{prices.numberOfMonths > 1 ? 'lub' : ''}} <span v-if="haveDiscount == true" style="text-decoration: line-through; font-size: 16px;">{{getPriceBeforeDiscount(prices.priceNetto)}}</span> <strong>{{prices.priceNetto}}</strong> PLN / {{getTimeForPrice(prices.numberOfMonths)}}</h2>
                                                            </div>
                                                        </v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> <strong>Dla {{item.accountNumbers}} odbiorców zgłoszeń</strong></v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Dowolna liczba obsługiwanych zgłoszeń</v-card-text>
                                                        <v-card-text class="small-padd"><i :class="item.forms ? 'fas fa-check color-icon-green' : 'fas fa-times color-icon-red'"></i> Formularz przyjmowania zgłoszeń</v-card-text>
                                                        <v-card-text class="small-padd"><i :class="item.emails ? 'fas fa-check color-icon-green' : 'fas fa-times color-icon-red'"></i> Emailowe przyjmowanie zgłoszeń</v-card-text>
                                                        <v-card-text class="small-padd"><i :class="item.phones ? 'fas fa-check color-icon-green' : 'fas fa-times color-icon-red'"></i> Telefoniczne przyjmowanie zgłoszeń</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Powiadomienia mailowe</v-card-text>
                                                        <v-card-text class="small-padd"><i :class="item.smsNotifications ? 'fas fa-check color-icon-green' : 'fas fa-times color-icon-red'"></i> Powiadomienia SMS</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Wsparcie mailowe</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Wsparcie telefoniczne</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Wzorcowa dokumentacja dla wdrożenia</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Video szkolenie dla odbiorców zgłoszeń</v-card-text>
                                                        <v-card-text class="small-padd"><i class="fas fa-check color-icon-green"></i> Video szkolenie dla pracowników</v-card-text>
                                                        <v-card-text class="small-padd"><i :class="item.onlineTraining ? 'fas fa-check color-icon-green' : 'fas fa-times color-icon-red'"></i> Szkolenie on-line dla odbiorców zgłoszeń</v-card-text>
                                                        <v-card-text class="footer">
                                                            <a class="btn circle btn-theme border btn-sm" @click="selectSubscription(item)">Wybierz</a>
                                                        </v-card-text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div><v-card-text style="text-align:center">*Wszystkie ceny są cenami netto</v-card-text></div>

                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import ErrorMainAdmin from '@/components/Modals/ErrorInfoOnlyMainAdmin.vue'
export default {
name: 'Subscription',
components: {
    ErrorMainAdmin
},
mixins: [validationMixin],
validations: {
    customerName: { required, minLength: minLength(3) },
    adress: { required, minLength: minLength(3) },
    city: { required, minLength: minLength(3) },
    nip: { minLength: minLength(10), maxLength: maxLength(10) },
    zipcode: { required, minLength: minLength(5) },
    selectModel: { required },
    selectPaymentMethod: { required },
    selectPackage: { required },
},
data() {
    return {
        mwcCompanyData: {
            companyName: 'MWC Spółka z ograniczoną odpowiedzialnością',
            companyZipcode: '60-002',
            companyAddress: 'Kowalewicka 12',
            companyCity: 'Poznań',
            nip: '7831678932',
            email: 'faktury@mwc.pl',
            phone: '61 66 61 977'
        },
        customer: {},
        user: {},
        main: [],
        global: {},
        showCustomerDataModal: false,
        showOrderSubscryptionModal: false,
        customerName: '',
        zipcode: '',
        adress: '',
        city: '',
        nip: '',
        showGlobalSettingsModals: true,
        packages: [],
        haveDiscount: false,
        partnerName: '',
        selectModel: {},
        paymentModels: [],
        selectPaymentMethod: {},
        paymentMethods: [],
        selectPackage: {},
        packagesToSelect: [],
        priceToPay: 0,
        priceToPayBrutto: 0,
        discountCode: '',
        discountPercent: 0,
    }
},
computed: {
    selectErrors() {
        const errors = []
        if (!this.$v.selectModel.$dirty) return errors
        !this.$v.select.selectModel && errors.push('Pole wymagane')
        return errors
    },
    selectErrors2() {
        const errors = []
        if (!this.$v.selectPaymentMethod.$dirty) return errors
        !this.$v.select.selectPaymentMethod && errors.push('Pole wymagane')
        return errors
    },
    selectErrors3() {
        const errors = []
        if (!this.$v.selectPackage.$dirty) return errors
        !this.$v.select.selectPackage && errors.push('Pole wymagane')
        return errors
    },
    nameErrors() {
        const errors = []
        if (!this.$v.customerName.$dirty) return errors
        !this.$v.customerName.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.customerName.required && errors.push('Pole jest wymagane.')
        return errors
    },
    cityErrors() {
        const errors = []
        if (!this.$v.city.$dirty) return errors
        !this.$v.city.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.city.required && errors.push('Pole jest wymagane.')
        return errors
    },
    adressErrors() {
        const errors = []
        if (!this.$v.adress.$dirty) return errors
        !this.$v.adress.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.adress.required && errors.push('Pole jest wymagane.')
        return errors
    },
    zipcodeErrors() {
        const errors = []
        if (!this.$v.zipcode.$dirty) return errors
        !this.$v.zipcode.minLength && errors.push('Pole nie może mieć mniej niż 5 znaków')
        !this.$v.zipcode.required && errors.push('Pole jest wymagane.')
        return errors
    },
    nipErrors() {
        const errors = []
        if (!this.$v.nip.$dirty) return errors
        !this.$v.nip.minLength && errors.push('Pole nie może mieć mniej niż 10 znaków')
        !this.$v.nip.maxLength && errors.push('Pole nie może mieć więcej niż 10 znaków')
        return errors
    },
},
async mounted() {
    try {
        if (this.isMainAdmin()) {
            this.main = (await Service.getCustomerDataSubscryption()).data;
            this.customer = this.main.customer;
            this.user = this.main.user;

            this.global = (await Service.getPackages()).data;
            this.packages = this.global.packages;
            this.haveDiscount = this.global.haveDiscount;
            this.partnerName = this.global.partnerName;
            this.discountPercent = this.global.discountPercent;
        }
    } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
  },
  methods: {
      isMainAdmin() {
          return this.$store.state.admin.type == 1;
      },
      getPriceBeforeDiscount(priceNetto) {
          return (priceNetto * 100)/(100 - this.discountPercent);
      },
      getTimeForPrice(numberOfMonths) {
          switch (numberOfMonths) {
              case 1:
                  return 'miesiąc';
              case 12:
                  return 'rok';
              default:
                  return numberOfMonths + ' miesięcy';
          }
      },
      checkPackage() {
          if (typeof (this.selectPackage) === 'string') {
              return this.packagesToSelect.find(x => x.name == this.selectPackage).id;
          }
          else {
              return this.selectPackage.id;
          }
      },
      changeSelectedPackage() {
          var pac = {};
          if (typeof (this.selectPackage) === 'string') {
              pac = this.packagesToSelect.find(x => x.name == this.selectPackage);
          }
          else {
              pac = this.selectPackage;
          }

          var mod = {};
          if (typeof (this.selectModel) === 'string') {
              mod = this.paymentModels.find(x => x.name == this.selectModel);
          }
          else {
              mod = this.selectModel;
          }

          if (pac != null) {
              var prices = pac.prices[0].priceCombination;
              this.priceToPay = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceNetto;
              this.priceToPayBrutto = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceBrutto;
          }
      },
      changeSelectedTime() {
          var pac = {};
          if (typeof (this.selectPackage) === 'string') {
              pac = this.packagesToSelect.find(x => x.name == this.selectPackage);
          }
          else {
              pac = this.selectPackage;
          }

          var mod = {};
          if (typeof (this.selectModel) === 'string') {
              mod = this.paymentModels.find(x => x.name == this.selectModel);
          }
          else {
              mod = this.selectModel;
          }

          if (pac != null) {
              var prices = pac.prices[0].priceCombination;
              this.priceToPay = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceNetto;
              this.priceToPayBrutto = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceBrutto;
          }
      },
      async checkDiscountCode() {
          try {
                var temp = {
                    discountCode: this.discountCode
                };

              var result = (await Service.checkDiscountCode(temp)).data;
                if (Object.keys(result).length > 0) {
                    this.packages = result.packages;
                    this.haveDiscount = result.haveDiscount;
                    this.partnerName = result.partnerName;
                    this.packagesToSelect = this.packages;

                    var pac = {};
                    if (typeof (this.selectPackage) === 'string') {
                        pac = this.packagesToSelect.find(x => x.name == this.selectPackage);
                    }
                    else {
                        pac = this.selectPackage;
                    }

                    var mod = {};
                    if (typeof (this.selectModel) === 'string') {
                        mod = this.paymentModels.find(x => x.name == this.selectModel);
                    }
                    else {
                        mod = this.selectModel;
                    }

                    var pacz = this.packages.find(x => x.id == pac.id);
                    var prices = pacz.prices[0].priceCombination;
                    this.priceToPay = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceNetto;
                    this.priceToPayBrutto = prices.find(x => x.numberOfMonths == mod.numberOfMonths).priceBrutto;
                }
              
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async selectSubscription(item) {
          try {
              var result = (await Service.checkCustomerData()).data;
              if (result == true) {
                  var orderData = (await Service.getFormOrderData()).data;
                  this.paymentModels = orderData.paymentModels;
                  this.paymentMethods = orderData.paymentMethods;
                  this.selectPaymentMethod = orderData.paymentMethods[0];
                  this.selectModel = orderData.paymentModels[0];
                  this.packagesToSelect = this.packages;
                  this.selectPackage = item;

                  var prices = this.selectPackage.prices[0].priceCombination;
                  this.priceToPay = prices.find(x => x.numberOfMonths == this.selectModel.numberOfMonths).priceNetto;
                  this.priceToPayBrutto = prices.find(x => x.numberOfMonths == this.selectModel.numberOfMonths).priceBrutto;

                  this.showOrderSubscryptionModal = true;
              }
              else {
                  alert("Brak uzupełnionych danych firmy/instytucji do faktury. Uzupełnij dane i spróbuj ponownie.")
              }
 
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async submitSubscriptionOrder() {
          try {
              if (confirm('Czy na pewno złożyć zamówienie i przejść do płatności?')) {

                  var pac = {};
                  if (typeof (this.selectPackage) === 'string') {
                      pac = this.packagesToSelect.find(x => x.name == this.selectPackage);
                  }
                  else {
                      pac = this.selectPackage;
                  }

                  var mod = {};
                  if (typeof (this.selectModel) === 'string') {
                      mod = this.paymentModels.find(x => x.name == this.selectModel);
                  }
                  else {
                      mod = this.selectModel;
                  }

                  var met = {};
                  if (typeof (this.selectPaymentMethod) === 'string') {
                      met = this.paymentMethods.find(x => x.name == this.selectPaymentMethod);
                  }
                  else {
                      met = this.selectPaymentMethod;
                  }

                  var temp = {
                      paymentModel: mod,
                      paymentMethod: met,
                      package: pac
                  };

                  var result = (await Service.createOrderSubscription(temp)).data;
                  if (result != null && result.length > 0) {
                      window.open(result, "_self");
                  }
                  else {
                      alert("Błąd tworzenia płatności!");
                  }
                  
              }
              else {
                  return;
              }
          } catch (e) {
              if (e.response !== undefined && e.response.status !== undefined)
                alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async submitCustomerData() {
          try {
              if (confirm('Czy zmienić dane korespondencyjne?')) {

                  if (this.nip.length > 0) {
                      if (this.nip.length != 10) {
                          alert("Błędny numer NIP");
                          return;
                      }
                  }

                  var temp = {
                      id: this.customer.id,
                      companyName: this.customerName,
                      companyZipcode: this.zipcode,
                      companyAddress: this.adress,
                      companyCity: this.city,
                      nip: this.nip
                  };

                  var result = (await Service.changeCustomerDataForAdmin(temp)).data;
                  if (Object.keys(result).length > 0) {
                      this.customer.companyName = result.companyName;
                      this.customer.companyZipcode = result.companyZipcode;
                      this.customer.companyAddress = result.companyAddress;
                      this.customer.companyCity = result.companyCity;
                      this.customer.nip = result.nip;
                      this.showCustomerDataModal = false;
                      this.showGlobalSettingsModals = true;
                  }
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      onlyNumber($event) {
          let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
          if ((keyCode < 48 || keyCode > 57)) {
              $event.preventDefault();
          }
      },
      closeModalCustomerData() {
          this.customerName = '';
          this.zipcode = '';
          this.adress = '';
          this.city = '';
          this.nip = '';
          this.showCustomerDataModal = false;
          this.showGlobalSettingsModals = true;
      },
      closeModalOrderSubscryption() {
          this.showCustomerDataModal = false;
          this.showGlobalSettingsModals = true;
          this.showOrderSubscryptionModal = false;
      },
      changeCustomerData() {
          this.customerName = this.customer.companyName;
          this.zipcode = this.customer.companyZipcode;
          this.adress = this.customer.companyAddress;
          this.city = this.customer.companyCity;
          this.nip = this.customer.nip;
          this.showGlobalSettingsModals = false;
          this.showCustomerDataModal = true;
      },
      formatDateToDisplay(value) {
          var date = new Date(value)
          var d = date.toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              weekday: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          });

          return d;
      },
  }
}

</script>

<style scope>
    .inactive {
        color: red;
        font-weight: bold;
    }

    .active {
        color: green;
        font-weight: bold;
    }

    .pricing-item {
        background: #ffffff none repeat scroll 0 0;
        -moz-box-shadow: 0 0 10px #cccccc;
        -webkit-box-shadow: 0 0 10px #cccccc;
        -o-box-shadow: 0 0 10px #cccccc;
        box-shadow: 0 0 10px #cccccc;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .pricing-header h4 {
        font-weight: 600;
        text-transform: uppercase;
        color: #232323;
        font-size: 20px;
    }

    .pricing-header h2 {
        color: #232323;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 1;
        margin-bottom: 0;
    }

    .color-yellow .pricing-header h2 {
        color: #ff9800;
    }

    .pricing-header h2 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .pricing-item .pricing-header span {
        font-family: "Poppins",sans-serif;
        font-weight: 400;
        text-transform: uppercase;
    }

    .pricing-header {
        background: #edf5ff none repeat scroll 0 0;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 5px;
        padding: 10px !important;
    }

    .pricing-item .footer {
        padding: 10px;
    }

    .pricing-area .pricing-item.active .pricing-header {
        background: #4ac4f3 none repeat scroll 0 0;
    }

    .pricing-area.color-yellow .pricing-item.active .pricing-header {
        background: #ff9800 none repeat scroll 0 0;
    }

    .pricing-area .pricing-item.active .pricing-header h2,
    .pricing-area .pricing-item.active .pricing-header h4,
    .pricing-area .pricing-item.active .pricing-header span {
        color: #ffffff;
    }

        .pricing-area .pricing-item.active .pricing-header span.badge {
            background: #ffffff none repeat scroll 0 0;
            color: #232323;
        }

    .pricing-item li i {
        color: #4ac4f3;
        margin-right: 5px;
    }

    .pricing-item li i.fa-times {
        color: #e22626;
    }

    .color-icon-red {
        color: red;
    }

    .color-icon-green {
        color: green;
    }

    .small-padd {
        padding: 5px;
    }

</style>
