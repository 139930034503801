<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Nowe zgłoszenia</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="isCustomerActive()">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Lista nowych zgłoszeń</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newSignals.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newSignals.selected"
                                    :headers="newSignals.headers"
                                    :items="newSignals.signals"
                                    :search="newSignals.search"
                                    :loading="loading"
                                    loading-text="Ładowanie... Proszę czekać"
                                    item-key="identifier"
                                    :items-per-page.sync="itemsPerPage"
                                    :hide-default-footer="false"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.icon`]="{ item }">
                              <v-icon v-if="item.icon">
                                  mdi-paperclip
                              </v-icon>
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                              <v-icon v-if="item.type == 2">
                                  mdi-phone
                              </v-icon>
                              <v-icon v-if="item.type == 3">
                                  mdi-email-outline
                              </v-icon>
                          </template>
                          <template v-slot:[`item.show`]="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="showSignal(item)" v-on="on">
                                          <v-icon color="green">mdi-open-in-new</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>POKAŻ</span>
                              </v-tooltip>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>
      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
        name: 'New',
        components: {
            ErrorSubscription
        },
  data() {
    return {
        newSignals: [],
        signals: [],
        newMessages: [],
        signalsDb: [],
        itemsPerPage: 10,
        loading: true
    }
  },
async mounted() {
    try {

        if (this.isCustomerActive()) {
            this.main = (await Service.getNewSignals(20)).data,
                this.signalsDb = this.main.signals,
                this.newSignals = {
                    selected: [],
                    search: '',
                    headers: [
                        {
                            text: 'Identyfikator',
                            align: 'start',
                            sortable: false,
                            value: 'identifier',
                            width: "1%"
                        },
                        { text: '', value: 'icon', sortable: false, width: "1%" },
                        { text: '', value: 'type', sortable: false, width: "1%" },
                        { text: 'Data wpływu', value: 'datetime' },
                        { text: 'Dni', value: 'days' },
                        { text: 'Odpowiedzi', value: 'replaysCount' },
                        { text: 'Etykieta', value: 'title' },
                        { text: 'Kategoria', value: 'category' },
                        { text: '', value: 'show', sortable: false },
                    ],
                    signals: this.signalsDb,
                },
                this.loading = false
        }

        
        } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
        
  },
watch: {
      itemsPerPage: {
          handler() {
              if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                  this.loadRecords();
              }
        },
        deep: true,
      },
    },
  methods: {
      isCustomerActive() {
          return this.$store.state.admin.customerIsActive;
      },
      showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id } })
      },
      async loadRecords() {
          try {
              this.loading = true;
              this.main = (await Service.getNewSignals(this.itemsPerPage)).data;
                  this.signalsDb = this.main.signals,
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                              width: "1%"
                          },
                          { text: '', value: 'icon', sortable: false, width: "1%" },
                          { text: '', value: 'type', sortable: false, width: "1%" },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Odpowiedzi', value: 'replaysCount' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'show', sortable: false },
                      ],
                      signals: this.signalsDb,
                  };
              this.loading = false;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="./Basic.scss" lang="scss">
</style>
