import Api from '@/services/Api'

export default {
    // auth
    activateAccount(req) {
        return Api().post('auth/activateAccount', req)
    },
    resetPassForAdmin(req) {
        return Api().post('auth/resetPassForAdmin', req)
    },
    passwordReset(req) {
        return Api().post('auth/resetPassword', req)
    },
    register(req) {
        return Api().post('auth/register', req)
    },
    loginUser(req) {
        return Api().post('auth/login', req)
    },
    changePasswordForAdmin(req) {
        return Api().post(`auth/changePasswordForAdmin`, req)
    },
    getEmailFromActivationLink(id) {
        return Api().get(`auth/getEmailFromActivationLink/${id}`)
    },
    getEmailFromResetLink(id) {
        return Api().get(`auth/getEmailFromResetLink/${id}`)
    },
    logout() {
        return Api().post('auth/logout')
    },
    getAdmin() {
        return Api().get(`auth/getAdmin`)
    },
    updateTkAfterTransaction(data) {
        return Api().post(`auth/updateTk`, data)
    },

    // categories
    getCategoriesForCategoriesPanel() {
        return Api().get('categories/getCategoriesForCategoriesPanel')
    },
    getCategoryForEdit(req) {
        return Api().post(`categories/getCategoryForEdit`, req)
    },
    getFilesForCategory(id) {
        return Api().get(`categories/getFilesForCategory/${id}`)
    },
    getCategoryUsersForEdit(id) {
        return Api().get(`categories/getCategoryUsersForEdit/${id}`)
    },
    saveSelectedUsersToCategory(req) {
        return Api().post(`categories/saveSelectedUsersToCategory`, req)
    },
    addNewCategory(req) {
        return Api().post(`categories/addNewCategory`, req)
    },
    renameFileForCategories(catid, id, name) {
        return Api().post(`categories/renameFileForCategories/${catid}/${id}/${name}`)
    },
    removeFileForCategories(catid, id) {
        return Api().post(`categories/removeFileForCategories/${catid}/${id}`)
    },

    // supscryption
    getCustomerDataSubscryption() {
        return Api().get('subscription/getCustomerDataSubscryption')
    },
    checkCustomerData() {
        return Api().get('subscription/checkCustomerData')
    },
    getFormOrderData() {
        return Api().get('subscription/getFormOrderData')
    },
    getPackages() {
        return Api().get('subscription/getPackages')
    },
    checkDiscountCode(data) {
        return Api().post(`main/checkDiscountCode`, data)
    },

    // main
    getNewSignals(count) {
        return Api().get(`main/getNewSignals/${count}`)
    },
    getLogs(lg) {
        return Api().get(`main/getLogs/${lg}`)
    },
    getLogsForAdmin(req) {
        return Api().post(`main/getLogsForAdmin`, req)
    },
    getLogsForSpecyficSignal(id) {
        return Api().get(`main/getLogsForSpecyficSignal/${id}`)
    },
    changeSygnalTypesData(req) {
        return Api().post(`main/changeSygnalTypesData`, req)
    },
    getUsersForAdmin() {
        return Api().get('main/getUsersForAdmin')
    },
    downloadReportToFile(data) {
        return Api().post(`main/downloadReportToFile`, data, { responseType: 'arraybuffer' })
    },
    changeCustomerDataForAdmin(req) {
        return Api().post(`main/changeCustomerDataForAdmin`, req)
    },
    getMessagesOpenSignals(count) {
        return Api().get(`main/getMessagesOpenSignals/${count}`)
    },
    getAllSignals(count) {
        return Api().get(`main/getAllSignals/${count}`)
    },
    getCustomerData() {
        return Api().get('main/getCustomerData')
    },
    getSpecificSignal(id) {
        return Api().get(`main/getSpecificSignal/${id}`)
    },
    getOpenSignals(count) {
        return Api().get(`main/getOpenSignals/${count}`)
    },
    getUsersForUsersPanel() {
        return Api().get('main/getUsersForUsersPanel')
    },
    moveSygnalToNewCategory(req) {
        return Api().post(`main/moveSygnalToNewCategory`, req)
    },
    removeUser(id) {
        return Api().post(`main/removeUser/${id}`)
    },
    removeCategory(id) {
        return Api().post(`main/removeCategory/${id}`)
    },
    getArchiveSignals(count) {
        return Api().get(`main/getArchiveSignals/${count}`)
    },
    addToArchive(req) {
        return Api().post(`main/addToArchive`, req)
    },
    deleteSignal(req) {
        return Api().post(`main/deleteSignal`, req)
    },
    sendReply(req) {
        return Api().post('main/sendReply', req)
    },
    getLinkToForm() {
        return Api().get(`main/getLinkToForm`)
    },
    sendSignal(req) {
        return Api().post('main/sendSignal', req)
    },
    addNotesForSignal(req) {
        return Api().post(`main/addNotesForSignal`, req)
    },
    updateNotesForSignal(data) {
        return Api().post(`main/updateNotesForSignal`, data)
    },
    showMessages(req) {
        return Api().post(`main/showMessages`, req)
    },
    showNotesForSignal(id) {
        return Api().get(`main/showNotesForSignal/${id}`)
    },
    saveDomainAlias(data) {
        return Api().post(`main/saveDomainAlias`, data)
    },
    getAdminName() {
        return Api().get(`main/getAdminName`)
    },
    allMessages() {
        return Api().get('main/getallMessages')
    },
    getAllSignalsForType(count, type) {
        return Api().get(`main/getAllSignalsForType/${count}/${type}`)
    },
    getDashboardSignalsForType(count, type) {
        return Api().get(`main/getDashboardSignalsForType/${count}/${type}`)
    },
    main() {
        return Api().get('main/panel')
    },
    addOrUpdateUser(req) {
        return Api().post('main/addOrUpdateUser', req)
    },
    savePrivateFileForSignalByAdmin(id, req) {
        return Api().post(`main/savePrivateFileForSignalByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    saveFileForSignalByAdmin(id, req) {
        return Api().post(`main/saveFileForSignalByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    saveFileForCategoriesByAdmin(id, req) {
        return Api().post(`main/saveFileForCategoriesByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    saveCustomerLogoByAdmin(req) {
        return Api().post(`main/saveCustomerLogoByAdmin`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    downloadPrivateFile(req) {
        return Api().post(`main/downloadPrivateFile`, req, { responseType: 'arraybuffer' })
    },
    downloadFile(req) {
        return Api().post(`main/downloadFile`, req, { responseType: 'arraybuffer' })
    },
    removePrivateFile(id, fileId) {
        return Api().post(`main/removePrivateFile/${id}/${fileId}`)
    },
    removeFile(voteId, meetingId, fileId) {
        return Api().post(`main/removeFile/${voteId}/${meetingId}/${fileId}`)
    },
    removeCustomerLogoByAdmin() {
        return Api().post(`main/removeCustomerLogoByAdmin`)
    },
    

    // payment
    createOrderSubscription(data) {
        return Api().post(`payment/createOrderSubscription`, data)
    },
    getOrderList() {
        return Api().get(`payment/getOrderList`)
    },
    getOrderInfo(token) {
        return Api().get(`payment/order/${token}`)
    },
    setNoPaymentStatus(token) {
        return Api().get(`payment/order-cancel/${token}`)
    },
    //generateInvoiceForOrder(data) {
    //    return Api().post(`payment/generateInvoiceForOrder`, data)
    //},
    getInvoiceOrderForClient(id) {
        return Api().get(`payment/getInvoiceOrderForClient/${id}`)
    },

    // inbox
    getSpecificSignalForInbox(req) {
        return Api().post(`inbox/getSpecificSignalForInbox`, req)
    },
    getFieldsForCategory(req) {
        return Api().post(`inbox/getFieldsForCategory`, req)
    },
    showMessagesForInbox(req) {
        return Api().post(`inbox/showMessagesForInbox`, req)
    },
    sendReplyForInbox(req) {
        return Api().post('inbox/sendReplyForInbox', req)
    },
    getNewToken(req) {
        return Api().post(`inbox/getNewToken`, req)
    },
    getSygnaliForm(id) {
        return Api().get(`inbox/getSygnaliForm/${id}`)
    },
    loginToInbox(req) {
        return Api().post('inbox/loginToInbox', req)
    },
    loginToInboxByPhone(req) {
        return Api().post('inbox/loginToInboxByPhone', req)
    },
    logoutInbox(req) {
        return Api().post('inbox/logoutInbox', req)
    },
    saveFileForSignal(domain, req) {
        return Api().post(`inbox/saveFileForSignal/${domain}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    saveFileForSignalInbox(req) {
        return Api().post(`inbox/saveFileForSignalInbox`, req, {
            headers: {
            'Content-Type': 'multipart/form-data',
            }
        })
    },
    downloadAttachment(req) {
        return Api().post(`inbox/downloadAttachment`, req, { responseType: 'arraybuffer' })
    },
    downloadFileForInbox(req) {
        return Api().post(`inbox/downloadFileForInbox`, req, { responseType: 'arraybuffer' })
    },
    
}
