<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Otwarte zgłoszenia</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="isCustomerActive()">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Lista otwartych zgłoszeń</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="openSignals.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="openSignals.selected"
                                    :headers="openSignals.headers"
                                    :items="openSignals.signals"
                                    :search="openSignals.search"
                                    item-key="identifier"
                                    :loading="loading"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage"
                                    :hide-default-footer="false"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.icon`]="{ item }">
                              <v-icon v-if="item.icon">
                                  mdi-paperclip
                              </v-icon>
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                              <v-icon v-if="item.type == 2">
                                  mdi-phone
                              </v-icon>
                              <v-icon v-if="item.type == 3">
                                  mdi-email-outline
                              </v-icon>
                          </template>
                          <template v-slot:[`item.show`]="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="showSignal(item)" v-on="on">
                                          <v-icon color="green">mdi-open-in-new</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>POKAŻ</span>
                              </v-tooltip>
                          </template>

                          <template v-slot:[`item.actions`]="{ item }" v-if="canArchivize">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="addToArchive(item)" v-on="on">
                                          <v-icon color="brown">mdi-archive</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>ZARCHIWIZUJ</span>
                              </v-tooltip>
                          </template>

                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>
      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
        name: 'Open',
        components: {
            ErrorSubscription
        },
  data() {
    return {
        openSignals: [],
        signals: [],
        newMessages: [],
        signalsDb: [],
        canArchivize: false,
        itemsPerPage: 10,
        loading: true
    }
  },
  async mounted() {
      try {

          if (this.isCustomerActive()) {
              this.main = (await Service.getOpenSignals(20)).data,
                  this.signalsDb = this.main.signals,
                  this.canArchivize = this.main.login.canArchivize,
                  this.openSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                              width: "1%"
                          },
                          { text: '', value: 'icon', sortable: false, width: "1%" },
                          { text: '', value: 'type', sortable: false, width: "1%" },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Odpowiedzi', value: 'replaysCount' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'show', sortable: false },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.signalsDb,
                  },
                  this.loading = false
          }
          
      } catch (e) {
          if (e.response.status == 401)
              this.$router.push({ name: 'Login' })
      }
  },
  watch: {
      itemsPerPage: {
          handler() {
              if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                  this.loadRecords();
              }
        },
        deep: true,
      },
    },
  methods: {
    isCustomerActive() {
        return this.$store.state.admin.customerIsActive;
    },
    async addToArchive(item){
          try {
              if (confirm('Czy na pewno przenieść zgłoszenie o identyfikatorze: ' + item.identifier + ' do archiwum?')) {

                  var temp = {
                      ip: this.$store.state.ip,
                      id: item.id
                  }

                  var result = (await Service.addToArchive(temp));
                  if (result.status == 200) {
                      var index = this.openSignals.signals.findIndex(x => x.identifier == item.identifier);
                      if (index != -1)
                          this.openSignals.signals.splice(index, 1);
                  }
              } else {
                  return;
              }
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
    },
      showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id } })
      },
      async loadRecords() {
          try {
            this.loading = true;
            this.main = (await Service.getOpenSignals(this.itemsPerPage)).data;
            this.signalsDb = this.main.signals,
            this.canArchivize = this.main.login.canArchivize,
            this.openSignals = {
                selected: [],
                search: '',
                headers: [
                    {
                        text: 'Identyfikator',
                        align: 'start',
                        sortable: false,
                        value: 'identifier',
                        width: "1%"
                    },
                    { text: '', value: 'icon', sortable: false, width: "1%" },
                    { text: '', value: 'type', sortable: false, width: "1%" },
                    { text: 'Data wpływu', value: 'datetime' },
                    { text: 'Dni', value: 'days' },
                    { text: 'Odpowiedzi', value: 'replaysCount' },
                    { text: 'Etykieta', value: 'title' },
                    { text: 'Kategoria', value: 'category' },
                    { text: '', value: 'show', sortable: false },
                    { text: '', value: 'actions', sortable: false },
                ],
                signals: this.signalsDb
            }
              this.loading = false;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="./Basic.scss" lang="scss">
</style>
