<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Wszystkie zgłoszenia</h1>

            <ErrorSubscription :isCustomerActive="isCustomerActive()" />

            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Nowe zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="newSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="newSignals.selected"
                                      :headers="newSignals.headers"
                                      :items="newSignals.signals"
                                      :search="newSignals.search"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>

                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Otwarte zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="openSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="openSignals.selected"
                                      :headers="openSignals.headers"
                                      :items="openSignals.signals"
                                      :search="openSignals.search"
                                      item-key="identifier"
                                      :loading="loading2"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage2"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>

                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }" v-if="canArchivize">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="addToArchive(item)" v-on="on">
                                            <v-icon color="brown">mdi-archive</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>ZARCHIWIZUJ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Archiwalne zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="archiveSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="archiveSignals.selected"
                                      :headers="archiveSignals.headers"
                                      :items="archiveSignals.signals"
                                      :search="archiveSignals.search"
                                      item-key="identifier"
                                      :loading="loading3"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage3"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>
                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }" v-if="canArchivize">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="deleteSignal(item)" v-on="on">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>USUŃ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
    export default {
        name: 'All',
        components: {
            ErrorSubscription
        },
        data() {
            return {
                newSignals: [],
                openSignals: [],
                archiveSignals: [],
                signals: [],
                signalsDbNew: [],
                signalsDbOpen: [],
                signalsDbArchive: [],
                canArchivize: false,
                itemsPerPage: 10,
                itemsPerPage2: 10,
                itemsPerPage3: 10,
                loading: true,
                loading2: true,
                loading3: true
            }
        },
        async mounted() {
            try {

                if (this.isCustomerActive()) {
                    this.main = (await Service.allMessages()).data,
                        this.signalsDbNew = this.main.newSignals,
                        this.signalsDbOpen = this.main.openSignals,
                        this.signalsDbArchive = this.main.archiveSignals,
                        this.canArchivize = this.main.canArchivize,
                        this.openSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbOpen,
                        },
                        this.loading2 = false,
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                            ],
                            signals: this.signalsDbNew,
                        },
                        this.loading = false,
                        this.archiveSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Data zakończenia', value: 'archiveTime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbArchive,
                        },
                        this.loading3 = false
                }
               

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords(this.itemsPerPage, 1);
                    }
                },
                deep: true,
            },
            itemsPerPage2: {
                handler() {
                    if (this.itemsPerPage2 > 20 || this.itemsPerPage2 == -1) {
                        this.loadRecords(this.itemsPerPage2, 2);
                    }
                },
                deep: true,
            },
            itemsPerPage3: {
                handler() {
                    if (this.itemsPerPage3 > 20 || this.itemsPerPage3 == -1) {
                        this.loadRecords(this.itemsPerPage3, 3);
                    }
                },
                deep: true,
            },
        },
        methods: {
            isCustomerActive() {
                return this.$store.state.admin.customerIsActive;
            },
            showSignal(item) {
                this.$router.push({ name: 'Signal', params: { identifier: item.id } })
            },
            async addToArchive(item) {
                try {
                    if (confirm('Czy na pewno przenieść zgłoszenie o identyfikatorze: ' + item.identifier + ' do archiwum?')) {

                        var temp = {
                            ip: this.$store.state.ip,
                            id: item.id
                        }

                        var result = (await Service.addToArchive(temp));
                        if (result.status == 200) {
                            var index = this.openSignals.signals.findIndex(x => x.identifier == item.identifier);
                            if (index != -1) {
                                item.archiveTime = result.data;
                                this.archiveSignals.signals.push(item);
                                this.openSignals.signals.splice(index, 1);
                            }
                        }
                    } else {
                        return;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async deleteSignal(item) {
                try {
                    if (confirm('Czy na pewno usunąć zgłoszenie o identyfikatorze: ' + item.identifier + '? Pamiętaj, ta operacja jest nieodwracalna.')) {

                        var temp = {
                            ip: this.$store.state.ip,
                            id: item.id
                        }

                        var result = (await Service.deleteSignal(temp));
                        if (result.status == 200) {
                            var index = this.archiveSignals.signals.findIndex(x => x.identifier == item.identifier);
                            if (index != -1) {
                                this.archiveSignals.signals.splice(index, 1);
                            }
                        }
                    } else {
                        return;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async loadRecords(count, type) {
                try {
                    if (type == 1)
                        this.loading = true;
                    if (type == 2)
                        this.loading2 = true;
                    if (type == 3)
                        this.loading3 = true;

                    var result = (await Service.getAllSignalsForType(count, type)).data;
                    
                    if (type == 1) {
                        this.signalsDbNew = result;
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                            ],
                            signals: this.signalsDbNew,
                        }
                    }
                    if (type == 2) {
                        this.signalsDbOpen = result;
                        this.openSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbOpen,
                        }
                    }
                    if (type == 3) {
                        this.signalsDbArchive = result;
                        this.archiveSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                            ],
                            signals: this.signalsDbArchive,
                        }
                    }

                    this.loading = false;
                    this.loading2 = false;
                    this.loading3 = false;
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="./Basic.scss" lang="scss">
</style>
