var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Archiwalne zgłoszenia")]),_c('ErrorSubscription',{attrs:{"isCustomerActive":_vm.isCustomerActive()}}),(_vm.isCustomerActive())?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Lista archiwalnych zgłoszeń")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.archiveSignals.search),callback:function ($$v) {_vm.$set(_vm.archiveSignals, "search", $$v)},expression:"archiveSignals.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.archiveSignals.headers,"items":_vm.archiveSignals.signals,"search":_vm.archiveSignals.search,"item-key":"identifier","loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","items-per-page":_vm.itemsPerPage,"hide-default-footer":false,"footer-props":{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-icon',[_vm._v(" mdi-paperclip ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 2)?_c('v-icon',[_vm._v(" mdi-phone ")]):_vm._e(),(item.type == 3)?_c('v-icon',[_vm._v(" mdi-email-outline ")]):_vm._e()]}},{key:"item.show",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.showSignal(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("POKAŻ")])])]}},(_vm.canArchivize)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.deleteSignal(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("USUŃ")])])]}}:null],null,true),model:{value:(_vm.archiveSignals.selected),callback:function ($$v) {_vm.$set(_vm.archiveSignals, "selected", $$v)},expression:"archiveSignals.selected"}})],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }